export const customTheme = {
  colours: {
    primary: "#0057D9",
    primaryLight: "#E5EDFB",
    white: "#fff",
    whiteOff: "#F6F6F6",
    black: "#000000",
    blackLight: "#2A2A2A",
    blackOff: "#707070"
  },
  fonts: {
    fontFamily: {
      light: "Satoshi-Light",
      lightItalic: "Satoshi-LightItalic",
      regular: "Satoshi-Regular",
      regularItalic: "Satoshi-Italic",
      medium: "Satoshi-Medium",
      mediumItalic: "Satoshi-MediumItalic",
      bold: "Satoshi-Bold",
      boldItalic: "Satoshi-BoldItalic",
      black: "Satoshi-Black",
      blackItalic: "Satoshi-BlackItalic"
    }
  }
};
