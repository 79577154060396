import BottomNavbarUI from "./BottomNavbar.UI";
import userIcon from "../../assets/svgs/userIcon.svg";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../Button";
import { customTheme } from "../../helpers/theme";
import logo from "../../assets/pngs/logo.svg";
import { useContext, useState } from "react";
import Storage from "../../helpers/storage";
import CustomModal from "../Modal/modal";
import { FormItem, InputTab } from "../CustomInput";
import { Col, Form, Row } from "antd";
import { PageTitle, Spacer, SubmitBtn } from "../../pages/Authentication/AuthLayout";
import { createMeeting } from "../../helpers/Api";
import WebContext from "../../context/web-context";
import altlogo from "../../assets/pngs/logo.svg";
import NotificationMessage from "../NotificationMessage";
const URL = process.env.REACT_APP_MEET_URL;
const BottomNavbar = () => {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "")?.trim();
  const { user, meeting, setMeeting } = useContext(WebContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const IsLinkActive = (path) =>
    pathname.length > 0 ? pathname.includes(path) : path === "home" && true;

  const handleMeeting = () => {
    setOpenDrawer(false);
    let d = localStorage.getItem("isloggedIn");
    if (d === null || d === undefined) {
      return window.location.replace("/login");
    } else {
      setMeeting(true);
    }
  };
  const leftSideMenu = [
    {
      name: "Home",
      isActive: IsLinkActive("home"),
      to: "/",
      content: "Home"
    },
    {
      name: "Über MetaWeine",
      isActive: IsLinkActive("aboutus"),
      to: "/aboutus",
      content: "Über MetaWeine"
    },
    {
      name: "Kontakt",
      isActive: IsLinkActive("contactsales"),
      to: "/contactsales",
      content: "Kontakt"
    },
    {
      name: "Preise & Abonnements",
      isActive: IsLinkActive("subscription"),
      to: "/subscription-and-pricing",
      content: "Preise & Abonnements"
    }
  ];

  const rightSideMenu = [
    {
      name: "Meeting",
      isActive: IsLinkActive("meeting"),
      // to: "/meeting",
      content: (
        <CustomButton type="primary" block onClick={handleMeeting}>
          Meeting
        </CustomButton>
      )
    },
    Storage.loadToken()
      ? {
          name: "Logout",
          isActive: IsLinkActive("logout"),
          to: "/logout",
          content: (
            <CustomButton
              onClick={() => setOpenDrawer(false)}
              block
              color={customTheme.colours.primary}
              border={`1px solid ${customTheme.colours.primary}`}
              background={customTheme.colours.primaryLight}>
              Ausloggen
            </CustomButton>
          )
        }
      : {
          name: "Login",
          isActive: IsLinkActive("login"),
          to: "/login",
          content: (
            <CustomButton
              block={true}
              onClick={() => setOpenDrawer(false)}
              color={customTheme.colours.primary}
              border={`1px solid ${customTheme.colours.primary}`}
              background={customTheme.colours.primaryLight}>
              Anmeldung
            </CustomButton>
          )
        },
    {
      name: "user profile",
      isActive: IsLinkActive("profile"),
      to: "/profile",
      content: (
        <CustomButton
          onClick={() => setOpenDrawer(false)}
          block={false}
          color={customTheme.colours.primary}
          background={customTheme.colours.primaryLight}>
          <Image src={userIcon} />
        </CustomButton>
      )
    }
  ];

  const handleDrawer = (e) => {
    setOpenDrawer(e);
  };

  const handleMeetings = async (e, user) => {
    // if (e !== null && e !== undefined) {
    //   window.location.href = `https://meet.metaweine.de/${e.mname}`;
    // }
    // e.prevantDefault();
    let uid = localStorage.getItem("uid");
    const currentDate = new Date();
    const hour = currentDate.getHours();
    const minute = currentDate.getMinutes();
    let data = {
      hostId: uid,
      meetingStatus: "scheduled",
      meetingType: "meeting",
      meeting_name: e.mname,
      startTime: hour + ":" + minute,
      participants: []
    };
    const res = await createMeeting(data);
    if (res.status === 200) {
      window.location.href = `${URL + e.mname}#custom.meetingId=${
        res?.data?.data?.meetingId
      }&custom.hostId=${uid}&userInfo.displayName=${user.firstName + user.lastName}`;
    } else if (res.status === 400) {
      NotificationMessage("error", res?.data?.message);
    }
  };

  return (
    <>
      <BottomNavbarUI
        leftSideMenu={leftSideMenu}
        rightSideMenu={rightSideMenu}
        logo={logo}
        altLogo={altlogo}
        userIcon={userIcon}
        openDrawer={openDrawer}
        handleDrawer={handleDrawer}
      />
      {meeting && (
        <CustomModal
          handle={meeting}
          closable={false}
          footer={false}
          onCancel={() => setMeeting(!meeting)}
          maskClosable={true}>
          <Form onFinish={(e) => handleMeetings(e, user)}>
            <Row align={"middle"} justify={"center"}>
              <Col span={24}>
                <Spacer size={"10px"} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <PageTitle>Platz hinzufügen</PageTitle>
              </Col>
              <Col span={24}>
                <Spacer size={"10px"} />
              </Col>
              <Col span={24}>
                <FormItem
                  name={"mname"}
                  rules={[
                    {
                      required: true,
                      message: "Bitte geben Sie den Raumnamen ein"
                    }
                  ]}>
                  <InputTab placeholder={"Geben Sie den Raumnamen ein"} />
                </FormItem>
              </Col>
              <Col xs={14} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <SubmitBtn size={"large"} type={"submit"} btnlabel={"Raum verbinden"} />
              </Col>
            </Row>
          </Form>
        </CustomModal>
      )}
    </>
  );
};

export default BottomNavbar;

const Image = styled.img`
  width: 15.377px;
  padding: 0;
  margin-left: -1.5px;
  margin-bottom: -2.5px;
`;
