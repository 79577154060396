import FooterUI from "./Footer.UI";
import Footerbg from "../../assets/pngs/footerbg.png";
import FooterImg from "../../assets/svgs/footer.svg";
import logo from "../../assets/pngs/logo-white.svg";
import emailIcon from "../../assets/pngs/email.png";
import phoneIcon from "../../assets/svgs/phone.svg";
import location from "../../assets/svgs/location.svg";
import twitter from "../../assets/svgs/twiter.svg";
import instagram from "../../assets/svgs/instagram.svg";
import youtube from "../../assets/svgs/youtube.svg";
import fb from "../../assets/svgs/fb.svg";
// import { MeetingURL } from "../../constants";

const copwriteText = "Copyright ©2023 metaweine, Inc. All rights reserved.";

const socilaMediaIcons = [
  { name: "Twitter", icon: twitter },
  { name: "Instagram", icon: instagram },
  { name: "Youtube", icon: youtube },
  { name: "Facebook", icon: fb }
];

const ResourcesDetails = [
  { pageName: "Home", to: "/" },
  { pageName: "Über MetaWeine", to: "/aboutus" },
  { pageName: "Kontakt", to: "/contactsales" },
  { pageName: "Preise & Abonnements", to: "/subscription-and-pricing" }
];

const Staticpage = [
  { pageName: "Nutzungsbedingungen", to: "/termsofservice" },
  { pageName: "AGB", to: "/privacypolicy" },
  { pageName: "FAQ", to: "/faq" },
  { pageName: "Ticket erstellen", to: "/raise-ticket" }
];

const Footer = () => {
  return (
    <FooterUI
      copwriteText={copwriteText}
      Footerbg={Footerbg}
      FooterImg={FooterImg}
      logo={logo}
      emailIcon={emailIcon}
      phoneIcon={phoneIcon}
      socilaMediaIcons={socilaMediaIcons}
      ResourcesDetails={ResourcesDetails}
      Staticpage={Staticpage}
      location={location}
    />
  );
};

export default Footer;
