import { BrowserRouter } from "react-router-dom";
import RouteConfig from "./routes/RouteConfig";
import { ConfigProvider } from "antd";
import { customTheme } from "./helpers/theme";
import { WebProvider } from "./context/web-context";
// import { WebProvider } from "./context/web-context";
import CookieConsent from "react-cookie-consent";
// import styled from "styled-components";
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          primary: customTheme.colours.primary
        }
      }}>
      <BrowserRouter>
        <WebProvider>
          <RouteConfig />
        </WebProvider>
      </BrowserRouter>
      {/* <PostionRight> */}
      <CookieConsent
        enableDeclineButton
        location="bottom"
        style={{
          width: "400px",
          color: "black",
          background: "white",
          right: "0px",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
          justifycontent: "center",
          left: "unset",
          position: "fixed"
        }}
        declineButtonStyle={{
          backgroundColor: "#1677ff",
          border: "px solid lightblue",
          borderRadius: "5px",
          color: "white"
        }}
        buttonStyle={{
          backgroundColor: "#1677ff",
          border: "px solid lightblue",
          borderRadius: "5px",
          color: "white"
        }}>
        Um dir ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies, um
        Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn du diesen Technologien
        zustimmst, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website
        verarbeiten. Wenn du deine Zustimmung nicht erteilst oder zurückziehst, können bestimmte
        Merkmale und Funktionen beeinträchtigt werden
      </CookieConsent>
      {/* </PostionRight> */}
    </ConfigProvider>
  );
}

export default App;

// export const PostionRight = styled.div``;
