import NotificationMessage from "../components/NotificationMessage";
import { deleteRequest, getRequest, postRequest, putRequest } from "./apiCalls";
import {
  BillingInfo,
  FAQ,
  Meeting,
  Membership,
  Plans,
  PrivacyPolicy,
  TermsofService,
  Testimonial,
  Ticket,
  User
} from "./Apiendpoint";

//Api for create meeting
export const createMeeting = async (data) => {
  try {
    const res = await postRequest(Meeting.createMeeting, data);
    return res;
  } catch (error) {    
    NotificationMessage("error", error?.response?.data?.message);
  }
};

//Api for Lougout
export const userLogout = async () => {
  try {
    // eslint-disable-next-line no-unused-vars
    const res = await postRequest(User.logOut);
    // console.log(res);
  } catch (error) {
    // console.log(error.message);
  }
};
//Api for forgot password
export const forgotPassword = async (data) => {
  try {
    const res = await postRequest(User.forgotPassword, data);
    NotificationMessage("success", res?.data?.message);
  } catch (e) {
    NotificationMessage("error", e?.response?.data?.message);
  }
};

// Api for ResetPassword
export const resetPassword = async (data) => {
  try {
    const res = await postRequest(User.updatePassword, data);
    NotificationMessage("success", res?.data?.message);
    return res;
  } catch (e) {
    NotificationMessage("error", e?.response?.data?.message);
  }
};

export const Changepassword = async (data) => {
  try {
    const res = await postRequest(User.changepassword, data);
    if (res.status === 200) {
      NotificationMessage("success", res?.data?.message);
    } else {
      NotificationMessage("error", "Ungültige Angaben");
    }
    return res.status;
  } catch (e) {
    NotificationMessage("error", e?.response?.data?.message);
  }
};
//api for get User Profile
export const getProfile = async (data) => {
  try {
    const res = await postRequest(User.getProfile, data);
    return res?.data?.data;
  } catch (e) {
    // console.log(e.message);
  }
};
//Api for Update User Profile
export const updateProfile = async (id, data) => {
  try {
    const res = await putRequest(User.updateProfile + id, data);
    if (res.status === 200) {
      NotificationMessage("success", res?.data?.message);
    } else {
      NotificationMessage("error", res?.response?.data?.message);
    }
    return res?.status;
  } catch (e) {
    NotificationMessage("error", "Bitte laden Sie ein gültiges Bild hoch");
  }
};

//Api for getPlan
export const getAllPlans = async () => {
  try {
    const res = await getRequest(Plans.getAllPlan);
    return res?.data?.data;
  } catch (e) {
    //
  }
};

// user Buy Plan API
export const buyUserPlan = async (data) => {
  try {
    const res = await postRequest(Membership.userMembership, data);
    if (res.status === 200) {
      NotificationMessage("success", res.data.message);
      return res;
    } else {
      NotificationMessage("error", "error Found ");
    }
    return res;
  } catch (error) {
    // console.log(error);
  }
};

// user Buy Plan API
export const updateUserPlan = async (id, data) => {
  try {
    const res = await putRequest(Membership.updateMembership + id, data);
    if (res.status === 200) {
      NotificationMessage("success", res.data.message);
      return res;
    } else {
      NotificationMessage("error", "error Found");
    }
  } catch (error) {
    // console.log(error);
  }
};

// api for plan payment
export const planPayment = async (data) => {
  try {
    const res = await postRequest(BillingInfo.billPayment, data);
    return res;
  } catch (error) {
    return error;
  }
};

// Api for get Membership by id
export const updateMembershipById = async (id) => {
  try {
    const res = await putRequest(`api/v1/memberships/${id}`);
    return res;
  } catch (error) {
    // console.log(error);
  }
};

// Api for get Membership by id
export const getMembershipByid = async (id) => {
  try {
    const res = await getRequest(`api/v1/memberships/${id}`);
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const getMembershipByUserId = async (data) => {
  try {
    const res = await getRequest(Membership.getMembershipbyUserId + data);
    if (res?.status === 200) {
      return res?.data?.data;
    } else {
      //
    }
  } catch (error) {
    // console.log(error);
  }
};

export const deleteMembership = async (id) => {
  try {
    const res = await deleteRequest(Membership.deleteMembership + id);
    if (res.status === 200) {
      NotificationMessage("success", res?.data?.message, "", 5);
    }
    return res.status;
  } catch (error) {
    //
  }
};

//Fetching FAQ data
export const getFaqData = async () => {
  try {
    const res = await getRequest(FAQ.getAllFAQ);
    return res;
  } catch (error) {
    //
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const res = await getRequest(PrivacyPolicy.getPrivacy);
    return res;
  } catch (error) {
    //
  }
};

//Raise ticket by user
export const raiseTicket = async (data) => {
  try {
    const res = await postRequest(Ticket.createTicket, data);
    return res.data;
  } catch (error) {
    //
  }
};

//get all testimonials data
export const getAllTestimonials = async () => {
  try {
    const res = await getRequest(Testimonial.getAllTestimonials);
    return res?.data?.data;
  } catch (error) {
    // console.log(error.message);
  }
};

//
export const getTermsofService = async () => {
  try {
    const res = await getRequest(TermsofService.getAllTermsofService);
    return res?.data?.data;
  } catch (error) {
    // console.log(error.message);
  }
};

// get Meeting Details By User id
export const getMeetingsByid = async (id) => {
  try {
    const res = await getRequest(User.getMeetingDetails + id);
    return res?.data?.data;
  } catch (error) {
    // console.log(error);
  }
};

// update Meeting Name
export const updateMeeting = async (id, data) => {
  try {
    const res = await putRequest(User.updateMeetingDetails + id, data);
    if (res.status === 200) {
      NotificationMessage("success", res?.data?.message);
    } else {
      NotificationMessage("error", res?.response?.data?.message);
    }
    return res?.status;
  } catch (e) {
    NotificationMessage("error", "Bitte laden Sie ein gültiges Bild hoch");
  }
};
