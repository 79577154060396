import { Col, Drawer, Image, Layout, Row } from "antd";
import styled from "styled-components";
import { customTheme } from "../../helpers/theme";
import { Link } from "react-router-dom";
import menuIcon from "../../assets/svgs/Menu.svg";

const BottomNavbarUI = ({
  leftSideMenu,
  rightSideMenu,
  logo,
  openDrawer,
  handleDrawer,
  altlogo
}) => {
  return (
    <Container className="layout" style={{ backgroundColor: "inherit" }}>
      <NavRow align={"middle"} justify={"center"}>
        <Col xs={1} sm={1} md={1} lg={0} xl={1} xxl={1} />
        <Col xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>
          <Link to={"/"}>
            <Image src={logo} preview={false} fallback={altlogo} width={140} />
          </Link>
        </Col>
        <Col offset={1} xs={0} sm={0} md={0} lg={10} xl={11} xxl={8}>
          <Row align={"middle"} justify={"center"}>
            {leftSideMenu.map((menu, i) => {
              return (
                <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6} key={i}>
                  <MenuItem
                    key={menu.name}
                    to={menu.to}
                    style={{ color: menu.isActive && customTheme.colours.primary }}>
                    {menu.content}
                  </MenuItem>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={2} />

        <Col xs={0} sm={0} md={0} lg={7} xl={6} xxl={6}>
          <Row align={"top"} justify={"start"} gutter={5}>
            {rightSideMenu.map((menu, i) => {
              return (
                <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8} key={i}>
                  <MenuItem
                    key={menu.name}
                    to={menu.to}
                    style={{ color: menu.isActive && customTheme.colours.primary }}>
                    {menu.content}
                  </MenuItem>
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col xs={15} sm={15} md={15} lg={0} xl={0} xxl={0} />
        <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}>
          <Image onClick={() => handleDrawer(true)} src={menuIcon} preview={false} />
        </Col>
      </NavRow>
      <CustomDrawer
        title={<Image src={logo} preview={false} width={150} />}
        placement={"left"}
        closable={false}
        onClose={() => handleDrawer(false)}
        open={openDrawer}
        key={"left"}
        width={300}>
        <Row align={"middle"} justify={"center"}>
          {leftSideMenu.map((menu, i) => {
            return (
              <Col xs={22} sm={22} md={22} lg={0} xl={0} xxl={0} key={i}>
                <DrawerItem
                  key={menu.name}
                  to={menu.to}
                  style={{ color: menu.isActive && customTheme.colours.primary }}
                  onClick={() => handleDrawer(false)}>
                  {menu.content}
                </DrawerItem>
              </Col>
            );
          })}
        </Row>
        <Row align={"middle"} justify={"center"} gutter={10}>
          {rightSideMenu.map((menu, i) => {
            return (
              <Col xs={22} sm={22} md={22} lg={0} xl={0} xxl={0} key={i}>
                <ButtonItem
                  key={menu.name}
                  to={menu.to}
                  style={{ color: menu.isActive && customTheme.colours.primary }}>
                  {menu.content}
                </ButtonItem>
              </Col>
            );
          })}
        </Row>
      </CustomDrawer>
    </Container>
  );
};

export default BottomNavbarUI;

const ButtonItem = styled(Link)`
  text-align: center;
  display: block;
  padding: 0.5rem;
  font-family: ${customTheme.fonts.fontFamily.bold};
  font-size: 1.2rem;
  text-transform: capitalize;
  color: ${customTheme.colours.black};
`;

const NavRow = styled(Row)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 15px;
  text-align: center;
`;

const Container = styled(Layout)`
  background-color: ${customTheme.colours.black};
  box-shadow: 0px 3px 6px #00000029;
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
  }
`;

const MenuItem = styled(Link)`
  font-family: ${customTheme.fonts.fontFamily.bold};
  text-transform: capitalize;
  color: ${customTheme.colours.black};
`;

const DrawerItem = styled(Link)`
  display: block;
  padding: 0.6rem;
  font-family: ${customTheme.fonts.fontFamily.bold};
  font-size: 1.2rem;
  text-transform: capitalize;
  color: ${customTheme.colours.black};
`;

const CustomDrawer = styled(Drawer)`
  .ant-drawer-header {
    text-align: center;
  }
`;
