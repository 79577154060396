export const User = {
  createUser: "api/v1/users/register/",
  loginUser: "api/v1/users/login",
  updatePassword: "api/v1/users/update-password",
  forgotPassword: "api/v1/users/forgot-Password",
  deleteUser: "api/v1/users/",
  getProfile: "api/v1/users/get-profile",
  updateProfile: "api/v1/users/update-profile/",
  getAllUser: "api/v1/users",
  getUser: "api/v1/users/",
  logOut: "api/v1/users/logOut",
  changepassword: "api/v1/users/change-Password",
  getMeetingDetails: "api/v1/meetings/getHostMeetings/",
  updateMeetingDetails: "api/v1/meetings/"
};

export const Plans = {
  createPlan: "api/v1/plans/",
  getAllPlan: "api/v1/plans",
  getplanById: "api/v1/plans/",
  deletePlan: "api/v1/plans/",
  updatePlan: "api/v1/plans/"
};

export const BillingInfo = {
  createBills: "api/v1/billings/",
  getAllBills: "api/v1/billings/",
  getBillbyId: "api/v1/billings/",
  deleteBills: "api/v1/billings/",
  updateBills: "api/v1/billings/",
  billPayment: "api/v1/payments"
};
export const ContactsApi = {
  createContact: "api/v1/contacts/",
  getAllContact: "api/v1/contacts/",
  getContactbyId: "api/v1/contacts/",
  updateContact: "api/v1/contacts/",
  deleteContact: "api/v1/contacts/"
};
export const Membership = {
  userMembership: "api/v1/memberships/",
  adminMembership: "api/v1/memberships/adminMembership",
  getMembership: "api/v1/memberships",
  getMembershipbyId: "api/v1/memberships/",
  deleteMembership: "api/v1/memberships/",
  updateMembership: "api/v1/memberships/",
  getMembershipbyUserId: "api/v1/memberships/get-memberships/"
};
export const Meeting = {
  createMeeting: "api/v1/meetings/createMeeting",
  getAllMeeting: "api/v1/meetings",
  getMeetingbyId: "api/v1/meetings/",
  deleteMeeting: "api/v1/meeting/",
  updateMeeting: "api/v1/meeting/"
};

export const FAQ = {
  createFAQ: "/api/v1/FAQs/",
  getAllFAQ: "/api/v1/FAQs/",
  getFAQbyId: "/api/v1/FAQs/",
  deleteFAQ: "/api/v1/FAQs/",
  updateFAQ: "/api/v1/FAQs/"
};

export const PrivacyPolicy = {
  createPrivacy: "api/v1/privacyPolicy/",
  getPrivacy: "api/v1/privacyPolicy/",
  deletePrivacy: "/api/v1/FAQs/",
  updatePrivacy: "api/v1/privacyPolicy/"
};

export const Ticket = {
  createTicket: "api/v1/tickets/",
  getAllTicket: "api/v1/tickets/",
  getTicketById: "api/v1/tickets/getTicketsByAdmin",
  responseTicket: "api/v1/tickets/",
  deleteTicket: "api/v1/tickets/"
};

export const Testimonial = {
  createTestimonials: "api/v1/testimonials/",
  getAllTestimonials: "api/v1/testimonials/",
  getTestimonialsById: "api/v1/testimonials/",
  updateTestimonials: "api/v1/testimonials/",
  deleteTestimonials: "api/v1/testimonials/"
};

export const TermsofService = {
  createTermsofService: "api/v1/testimonials/",
  getAllTermsofService: "api/v1/termsOfServices/",
  getTermsofServiceId: "api/v1/testimonials/",
  updateTermsofService: "api/v1/testimonials/",
  deleteTermsofService: "api/v1/testimonials/"
};
