import { Modal } from "antd";
import React from "react";
import styled from "styled-components";

const CustomModal = ({
  handle,
  closable,
  closeIcon,
  onCancel,
  title,
  children,
  onOk,
  footer,
  maskClosable
}) => {
  return (
    <>
      <ModalCover
        open={handle}
        closable={closable}
        closeIcon={closeIcon}
        onCancel={onCancel}
        confirmLoading={false}
        title={title}
        onOk={onOk}
        footer={footer}
        maskClosable={maskClosable}>
        {children}
      </ModalCover>
    </>
  );
};

export default CustomModal;

const ModalCover = styled(Modal)``;
