import { Checkbox, Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { customTheme } from "../../helpers/theme";

const CustomInput = ({
  value,
  readOnly,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  message,
  type,
  ref,
  onChange,
  capital,
  tabname
}) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          type: type,
          required: required ? true : false,
          message: message
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? " *" : ""}
          </LabelText>
          <InputTab
            ref={ref}
            disabled={disable}
            value={value}
            name={tabname}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            readOnly={readOnly}
            onChange={onChange}
            // defaultValue={value}
            contentEditable
            textstyle={capital ? "capitalize" : "lowercase"}
          />
        </Col>
      </Row>
    </FormItem>
  );
};
export default CustomInput;

export const EmailInput = ({
  value,
  readOnly,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  message,
  ref,
  onChange,
  capital,
  tabname
}) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          // type: type,
          required: required ? true : false,
          message: message
        },
        {
          pattern: /^[A-Za-z][A-Za-z0-9.]*@[A-Za-z0-9]+\.[A-Za-z]{2,}$/,
          message: "ungültige E-Mail-Adresse"
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? " *" : ""}
          </LabelText>
          <InputTab
            ref={ref}
            disabled={disable}
            value={value}
            name={tabname}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            readOnly={readOnly}
            onChange={onChange}
            // defaultValue={value}
            contentEditable
            textstyle={capital ? "capitalize" : "lowercase"}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomNameField = ({
  value,
  readOnly,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  defMsg,
  errMsg,
  ref,
  onChange,
  capital,
  tabname
}) => {
  const validateAlphabet = (_, value) => {
    const alphabetRegex = /^[A-Za-z]+$/;
    if (!value) {
      return Promise.reject(defMsg);
    } else if (!alphabetRegex.test(value)) {
      return Promise.reject(errMsg);
    }
    return Promise.resolve();
  };

  return (
    <FormItem
      name={name}
      rules={[
        {
          validator: validateAlphabet
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? " *" : ""}
          </LabelText>
          <InputTab
            ref={ref}
            disabled={disable}
            value={value}
            name={tabname}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            readOnly={readOnly}
            onChange={onChange}
            contentEditable
            textstyle={capital ? "capitalize" : "lowercase"}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomPassword = ({
  type,
  placeholder,
  name,
  label,
  prefix,
  suffix,
  forgot,
  message,
  required,
  autocomplete
}) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          type: { type },
          required: required ? required : "",
          message: message
        },
        {
          validator(_, val) {
            if (val === undefined) {
              return Promise.reject(message);
            }
            if (val !== null) {
              if (val.trim().length >= 6) return Promise.resolve("");
              return Promise.reject("Das Passwort muss mindestens 6 Ziffern lang sein");
            }
            return Promise.reject("Geben Sie ein gültiges Passwort ein");
          }
        }
      ]}>
      <Row align={"middle"} justify={"center"}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>{label}</LabelText>
          <PasswordTab
            placeholder={placeholder}
            autoComplete={autocomplete}
            name={name}
            prefix={prefix}
            suffix={suffix}
          />
        </Col>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <BottomText>{forgot ? forgot : ""}</BottomText>
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomTextField = ({
  placeholder,
  name,
  label,
  prefix,
  suffix,
  minrows,
  maxrows,
  required,
  message
}) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          required: required ? true : false,
          message: message
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>{label}</LabelText>
          <InputTab.TextArea
            autoSize={{
              minRows: minrows,
              maxRows: maxrows
            }}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomTextArea = ({
  value,
  row,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  message,
  type,
  showCount,
  maxrow,
  minrow
}) => {
  return (
    <FormItem
      name={name}
      rules={[
        {
          type: type,
          message: message,
          required: required ? required : false
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? " *" : ""}
          </LabelText>
          <TextArea
            showCount={showCount}
            placeholder={placeholder}
            rows={row}
            prefix={prefix}
            suffix={suffix}
            disabled={disable}
            value={value}
            autoSize={{
              minRows: minrow,
              maxRows: maxrow
            }}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomNumber = ({
  defval,
  message,
  max,
  min,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  controls
}) => {
  const validateNumber = (_, value) => {
    const numberRegex = /^[0-9]*$/;
    if (!value) {
      return Promise.reject(message);
    } else if (!numberRegex.test(value)) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  };
  return (
    <FormItem name={name} rules={[{ validator: validateNumber }]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? "" : ""}
          </LabelText>
          <InputNum
            size="large"
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            disabled={disable}
            min={min}
            max={max}
            defaultValue={defval}
            controls={controls ? controls : false}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomZipCode = ({
  defval,
  message,
  disable,
  placeholder,
  name,
  required,
  label,
  prefix,
  suffix,
  controls,
  lenMsg
}) => {
  const validateNumber = (minLength, maxLength, message, _, value) => {
    const numberRegex = /^[0-9]*$/;
    if (!value) {
      return Promise.reject("");
    } else if (!numberRegex.test(value)) {
      return Promise.reject(message);
    } else if (value.length < minLength || value.length > maxLength) {
      return Promise.reject(lenMsg);
    }
    return Promise.resolve();
  };
  return (
    <FormItem
      name={name}
      rules={[
        { required: true, message: message },
        {
          validator: (rule, value) => validateNumber(5, 10, message, rule, value)
        }
      ]}>
      <Row align={"middle"} justify={"center"} gutter={20}>
        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
          <LabelText>
            {label}
            {required ? "*" : ""}
          </LabelText>
          <InputNum
            size="large"
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            disabled={disable}
            defaultValue={defval}
            controls={controls ? controls : false}
          />
        </Col>
      </Row>
    </FormItem>
  );
};

export const CustomCheckBox = ({ errorMsg, name, children }) => {
  return (
    <>
      <FormItem
        name={name}
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: errorMsg
          }
        ]}>
        <Row align={"middle"} justify={"center"} gutter={20}>
          <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <Checkbox>{children}</Checkbox>
          </Col>
        </Row>
      </FormItem>
    </>
  );
};

export const InputNum = styled(InputNumber)`
  background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
  border: 1px solid #a4aeb2;
  box-shadow: 0px 3px 6px #0057d929;
  border-radius: 10px;
  opacity: 1;
  /* padding:8px; */
  width: 100%;

  :focus-within {
    background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
    border: 1px solid #0057d9;
    border-radius: 10px;
  }

  .ant-input-number-input {
    font-size: 14px;
  }
`;

export const InputTab = styled(Input)`
  background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
  border: 1px solid #a4aeb2;
  box-shadow: 0px 3px 6px #0057d929;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;
  text-transform: ${(props) => props.textstyle};

  :focus-within {
    background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
    border: 1px solid #0057d9;
    border-radius: 10px;
  }
`;

export const PasswordTab = styled(Input.Password)`
  background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
  border: 1px solid #a4aeb2;
  box-shadow: 0px 3px 6px #0057d929;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;

  :focus-within {
    background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
    border: 1px solid #0057d9;
    border-radius: 10px;
  }
`;

export const TextArea = styled(Input.TextArea)`
  background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
  border: 1px solid #a4aeb2;
  box-shadow: 0px 3px 6px #0057d929;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;

  :focus-within {
    background: ${customTheme.colours.white} 0% 0% no-repeat padding-box;
    border: 1px solid #0057d9;
    border-radius: 10px;
  }
`;

export const LabelText = styled.text`
  text-align: left;
  font: normal normal 0.9rem ${customTheme.fonts.fontFamily.medium};
  letter-spacing: 0px;
  color: ${customTheme.colours.black};
  text-transform: capitalize;
  opacity: 1;
  /* padding:8px; */
`;

export const BottomText = styled.text`
  text-align: right;
  right: 0px;
  padding-top: 20px;
  position: absolute;
  font: normal normal medium 2rem ${customTheme.fonts.fontFamily.medium};
  letter-spacing: px;
  color: ${customTheme.colours.primary};
  text-transform: capitalize;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    text-align: left;
    margin-left: 5%;
  }
`;
