import { Button, Col, Form, Image, Row, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import { customTheme } from "../../helpers/theme";

const AuthLayout = ({ image, title, children, loading }) => {
  return (
    <Rows justify={"center"} align={"middle"}>
      <Cols xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
        <Row align={"middle"} justify={"center"}>
          <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Spacer size={"80px"} />
          </Col>
          <Col xs={12} sm={12} md={22} lg={22} xl={18} xxl={14}>
            <HeadText>
              Lorem ipsum dolor
              <br />
              traf die <P> Grafik.</P>
            </HeadText>
          </Col>
          <Col span={24}>
            <Spacer size={"30px"} />
          </Col>
          <Col xs={12} sm={12} md={22} lg={22} xl={17} xxl={14}>
            <SubText>
              Lorem ipsum dolor sit amet. Die Grafiker und Typografen wissen das in Wirklichkeit nur
              zu gut.
            </SubText>
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={2} xxl={0} />

          <Col span={24}>
            <Spacer1 />
          </Col>
          <Col xs={8} sm={12} md={15} lg={12} xl={10} xxl={8}>
            <ImgTag src={image} preview={false} width={270} />
          </Col>
        </Row>
      </Cols>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Spin spinning={loading} delay={500}>
          <Row justify={"center"} align={"middle"} gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={12}>
              <PageTitle>{title}</PageTitle>
            </Col>
            <Col span={24} />
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <BottomLine />
            </Col>
            <Col span={24} />
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {children}
            </Col>
            <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
              <Spacer size={"120px"} />
            </Col>
          </Row>
        </Spin>
      </Col>
    </Rows>
  );
};

export default AuthLayout;

export const SubmitBtn = ({ btnlabel, onSubmit, type, disable, block }) => {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item>
            <SubmitButton
              block={block ? block : true}
              htmlType={type ? type : "submit"}
              onClick={onSubmit}
              size="large"
              disabled={disable ? disable : false}>
              <BtnText>{btnlabel}</BtnText>
            </SubmitButton>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const SubmitButton = styled(Button)`
  background: ${customTheme.colours.primary} 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  color: ${customTheme.colours.white};
  font: normal normal 3rem ${customTheme.fonts.fontFamily.regular};
`;

const BtnText = styled.text`
  text-align: center;
  font: normal normal medium 10rem ${customTheme.fonts.fontFamily.medium};
  letter-spacing: 0px;
  color: ${customTheme.colours.white};
  text-transform: capitalize;
  opacity: 1;
  padding: 10px;
`;

export const BottomLine = styled.hr`
  background-color: ${customTheme.colours.primary};
  height: 1px;
  /* width:11rem; */
`;

export const PageTitle = styled.div`
  text-align: center;
  position: relative;
  font: normal normal bold 2.3rem ${customTheme.fonts.fontFamily.bold};
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
`;

export const SubText = styled.text`
  text-align: left;
  font: normal normal normal 1.2rem ${customTheme.fonts.fontFamily.regular};
  letter-spacing: 0px;
  color: ${customTheme.colours.black};
  text-transform: lowercase;
`;

export const Spacer = styled.div`
  height: ${(props) => props.size};
`;

export const HeadText = styled.text`
  font: normal normal bold 3rem ${customTheme.fonts.fontFamily.medium};
  color: ${customTheme.colours.black};
  text-transform: capitalize;
`;

const Rows = styled(Row)`
  height: 800px;
  object-fit: contain;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;

  @media (max-width: 755px) {
    height: auto;
  }
`;

const Cols = styled(Col)`
  border-radius: 8px;
  background-color: ${customTheme.colours.primaryLight};
  height: 100%;
  object-fit: cover;
  @media (max-width: 1024px) {
    height: 95%;
  }
`;

const ImgTag = styled(Image)`
  /* /* height:500px; */
  /* width:100%;  */
`;

const P = styled.span`
  text-align: left;
  color: ${customTheme.colours.primary};
  text-transform: capitalize;
  font-family: "${customTheme.fonts.fontFamily.bold}";
`;

export const Spacer1 = styled.div`
  height: 80px;

  @media (max-width: 1920) {
    height: 20px;
  }

  @media (max-width: 1680) {
    height: 20px;
  }
`;
