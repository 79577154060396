import { Space } from "antd";
import { customTheme } from "../../helpers/theme";
import WebLayout from "../WebLayout";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// import { MeetingURL } from "../../constants";

// const { Text } = Typography;

const TopNabar = () => {
  const islogged = localStorage.getItem("isloggedIn");
  return (
    <div>
      <Container>
        <WebLayout>
          <Content>
            <Space size={"small"}>
              {/* <TopLink to={MeetingURL}>Join Meeting</TopLink> */}
              {islogged === "true" ? (
                ""
              ) : (
                <>
                  <TopLink to={"/signup"}>Anmeldem</TopLink>
                </>
              )}
            </Space>
          </Content>
        </WebLayout>
      </Container>
    </div>
  );
};

export default TopNabar;

const Container = styled.div`
  background-color: ${customTheme.colours.primary};
  height: 40px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
  line-height: 40px;
  color: ${customTheme.colours.white};
`;

// const ContentText = styled(Text)`
//   color: ${customTheme.colours.white};
// `;

const TopLink = styled(NavLink)`
  color: ${customTheme.colours.white};
  font-family: ${customTheme.fonts.fontFamily.regular};
`;
