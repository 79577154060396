import { lazy } from "react";
import componentLoader from "../helpers/utils";
const HomePage = lazy(() => componentLoader(() => import("../pages/HomePage")));
const AboutUsPage = lazy(() => componentLoader(() => import("../pages/AboutUsPage")));
const SubscriptionAndPricing = lazy(() =>
  componentLoader(() => import("../pages/SubscriptionAndPricing"))
);
const Signup = lazy(() => componentLoader(() => import("../pages/Authentication/Signup")));
const Login = lazy(() => componentLoader(() => import("../pages/Authentication/Login")));
const Forgot = lazy(() => componentLoader(() => import("../pages/Authentication/ForgotPassword")));
const Profile = lazy(() => componentLoader(() => import("../pages/UserProfile/index")));
const ContactSales = lazy(() => componentLoader(() => import("../pages/ContactSale")));
const Payment = lazy(() => componentLoader(() => import("../pages/Payment")));
const Logout = lazy(() => componentLoader(() => import("../pages/Authentication/logout")));
const ResetPassword = lazy(() =>
  componentLoader(() => import("../pages/Authentication/ResetPassword"))
);
const PrivacyPolicy = lazy(() => componentLoader(() => import("../pages/PrivacyPolicy")));
const TermsofService = lazy(() => componentLoader(() => import("../pages/TermsofService")));


const FAQ = lazy(() => componentLoader(() => import("../pages/FAQ")));

const RaiseTicket = lazy(() => componentLoader(() => import("../pages/Otherpages/RaiseTicket")));

export const routes = [
  {
    path: "/",
    element: <HomePage />,
    isProtected: false
  },
  {
    path: "/aboutus",
    element: <AboutUsPage />,
    isProtected: false
  },
  {
    path: "/subscription-and-pricing",
    element: <SubscriptionAndPricing />,
    isProtected: false
  },
  {
    path: "/signup",
    element: <Signup />,
    isProtected: false
  },
  {
    path: "/forgot-password",
    element: <Forgot />,
    isProtected: false
  },
  {
    path: "/login",
    element: <Login />,
    isProtected: false
  },
  {
    path: "/profile",
    element: <Profile />,
    isProtected: true
  },
  {
    path: "/contactsales",
    element: <ContactSales />,
    isProtected: false
  },
  {
    path: "/payment/:id",
    element: <Payment />,
    isProtected: true
  },
  {
    path: "/logout",
    element: <Logout />,
    isProtected: true
  },
  {
    path: "/reset-password/:id",
    element: <ResetPassword />,
    isProtected: false
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
    isProtected: false
  },
  {
    path: "/faq",
    element: <FAQ />,
    isProtected: false
  },
  {
    path: "/raise-ticket",
    element: <RaiseTicket />,
    isProtected: true
  },
  {
    path: "/termsofservice",
    element: <TermsofService />,
    isProtected: false
  }
];
