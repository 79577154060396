import styled from "styled-components";
import WebLayout from "../WebLayout";
import { customTheme } from "../../helpers/theme";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import CustomButton from "../Button";

const FooterUI = ({
  copwriteText,
  Footerbg,
  FooterImg,
  logo,
  emailIcon,
  phoneIcon,
  location,
  ResourcesDetails,
  Staticpage
}) => {
  const islogged = localStorage.getItem("isloggedIn");
  return (
    <>
      <Container style={{ backgroundImage: `url('${Footerbg}')` }}>
        <WebLayout>
          <Row className="footerImage" justify={"center"} gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={10}>
              <Image
                className="icon"
                width={"90%"}
                height={"90%"}
                src={FooterImg}
                preview={false}
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="footerButtons">
              <div className="mainText">
                Bereit zu
                <br /> Loslegen?
              </div>
              <div className="buttons">
                {islogged === "true" ? (
                  ""
                ) : (
                  <div className="btn1">
                    <Link to={"/signup"} onClick={() => window.scrollTo(0, 0)}>
                      <CustomButton type={"primary"}>Anmeldung</CustomButton>
                    </Link>
                  </div>
                )}
                <div className="btn2">
                  <Link to={"/subscription-and-pricing"} onClick={() => window.scrollTo(0, 0)}>
                    <CustomButton
                      width="10rem"
                      border={customTheme.colours.primary}
                      background={customTheme.colours.white}
                      color={customTheme.colours.primary}>
                      Preisgestaltung
                    </CustomButton>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="footerLinks" justify={"space-around"} align={"top"} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6} lg={6}>
              <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                {" "}
                <Image className="icon" src={logo} preview={false} width={200} />
              </Link>

              <div className="linkDescription">
                <Image
                  className="icon"
                  width={"20px"}
                  height={"auto"}
                  src={location}
                  preview={false}
                />
                <div className="description">Bahnhofstraße 20 54550 Daun Deutschland</div>
              </div>
              <div className="linkDescription">
                <Image
                  className="icon"
                  width={"20px"}
                  height={"auto"}
                  src={emailIcon}
                  preview={false}
                />
                <Link to={"mailto:info@vinomoldova.de"} className="description">
                  info@vinomoldova.de
                </Link>
              </div>

              <div className="linkDescription">
                <Image
                  className="icon"
                  width={"20px"}
                  height={"auto"}
                  src={phoneIcon}
                  preview={false}
                />
                <Link to={"tel:+49015228774337"} className="description">
                  +49 0 152 287 74337
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <div className="linkTitle">MENÜ</div>
              {ResourcesDetails.map((data) => (
                <div key={data.pageName} className="linkDescription">
                  <div className="description">
                    <Link to={data.to} className="link" onClick={() => window.scrollTo(0, 0)}>
                      {data.pageName}
                    </Link>
                  </div>
                </div>
              ))}
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
              <div className="linkTitle">RICHTLINIEN</div>
              {Staticpage?.map((item) => {
                return (
                  <Link
                    to={item.to}
                    key={item}
                    className="linkDescription"
                    onClick={() => window.scrollTo(0, 0)}>
                    <div className="description">{item.pageName}</div>
                  </Link>
                );
              })}
            </Col>
            {/* <Col xs={24} sm={12} md={6} lg={6} className="socialLinks">
              <div className="linkTitle">Social Media</div>
              {socilaMediaIcons.map((data) => (
                <div className="linkDescription" key={data.name}>
                  <Image
                    className="icon"
                    width={"inherit"}
                    height={"inherit"}
                    src={data.icon}
                    preview={false}
                  />
                  <div className="description">{data.name}</div>
                </div>
              ))}
            </Col> */}
          </Row>
        </WebLayout>
      </Container>
      <FooterBottom>{copwriteText}</FooterBottom>
    </>
  );
};

export default FooterUI;

const Container = styled.div`
  display: flex;
  background-color: ${customTheme.colours.blackLight};
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 60px;
  & .footerImage {
    margin: 5rem 0;

    .footerButtons {
      .buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        .btn1 {
          @media (max-width: 374px) {
            margin-left: 0px;
            width: 100%;
            padding: 27px;
          }
          @media (max-width: 374px) {
            margin-left: 0px;
            width: 100%;
            padding: 10px;
          }
        }
        .btn2 {
          margin-left: 10px;
          @media (max-width: 374px) {
            margin-left: 0px;
            width: 100%;
            padding: 10px;
          }
        }
        @media (max-width: 374px) {
          display: block;
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  & .mainText {
    color: ${customTheme.colours.white};
    font-family: ${customTheme.fonts.fontFamily.regular};
    font-size: 4.6rem;

    @media (max-width: 1680px) {
      font-size: 4.3rem;
    }
    @media (max-width: 1366px) {
      font-size: 4rem;
    }
    @media (max-width: 1080px) {
      font-size: 3.6rem;
    }
    @media (max-width: 1024px) {
      font-size: 3.5rem;
    }
    @media (max-width: 599px) {
      font-size: 5rem;
    }
    @media (max-width: 500px) {
      font-size: 4rem;
    }
    @media (max-width: 374px) {
      font-size: 3rem;
    }
  }
  & .footerLinks {
    & .icon {
      object-fit: contain;
      margin-bottom: 10px;
    }

    & .linkDescription {
      margin-top: 10px;
      display: flex;
      color: ${customTheme.colours.white};
      font-family: ${customTheme.fonts.fontFamily.regular};
      font-size: 1rem;

      .icon {
        color: ${customTheme.colours.white};
        /* background-color: #ffffff2e; */
        padding: 5px;
        object-fit: contain;
        margin-bottom: 0;
        width: 25px;
        margin-right: 0;
        border-radius: 7px;
      }

      & .description {
        margin-left: 15px;
        color: ${customTheme.colours.white};

        .link {
          color: ${customTheme.colours.white};
          font-family: ${customTheme.fonts.fontFamily.regular};
          font-size: 1rem;
        }
      }
    }
    & .linkTitle {
      display: inline-block;
      margin-left: 15px;
      padding-bottom: 12px;
      margin-bottom: 18px;
      border-bottom: 1px solid ${customTheme.colours.white};
      color: ${customTheme.colours.white};
      font-family: ${customTheme.fonts.fontFamily.regular};
      font-size: 1.4rem;
    }

    & .socialLinks {
      & .linkDescription {
        margin-left: 15px;
      }
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  background-color: ${customTheme.colours.primary};
  font-size: 0.75rem;
  color: ${customTheme.colours.white};
`;
