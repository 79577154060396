import NotificationMessage from "../components/NotificationMessage";
import axios from "./axios.config";
import { MESSAGES } from "../constants";

const handleRequest = (request) => {
  if (navigator.onLine) {
    return request;
  } else {
    NotificationMessage("error", MESSAGES.noInternet);
    throw new Error(MESSAGES.noInternet);
  }
};

export async function getRequest(url) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    // NotificationMessage("error", MESSAGES.apiError);
    throw error;
  }
}

export async function postRequest(url, payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    // NotificationMessage("error", MESSAGES.apiError);
    throw error;
  }
}

export async function putRequest(url, payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.put(url, payload);
    return response;
  } catch (error) {
    // NotificationMessage("error", MESSAGES.apiError);
    throw error;
  }
}

export async function patchRequest(url, payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.patch(url, payload);
    return response;
  } catch (error) {
    // NotificationMessage("error", MESSAGES.apiError);
    throw error;
  }
}

export async function deleteRequest(url) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    // NotificationMessage("error", MESSAGES.apiError);
    throw error;
  }
}

const api = {
  get: (url) => handleRequest(getRequest(url)),
  post: (url, payload) => handleRequest(postRequest(url, payload)),
  put: (url, payload) => handleRequest(putRequest(url, payload)),
  patch: (url, payload) => handleRequest(patchRequest(url, payload)),
  delete: (url) => handleRequest(deleteRequest(url)),
};

export default api;
