import NotificationMessage from "../components/NotificationMessage";
import SpinFC from "antd/es/spin";
import { MESSAGES } from "../constants";

export const handleApiResponseSuccess = (msg) => {
  NotificationMessage("success", msg || MESSAGES.requestSuccess);
};

export const handleApiResponseErrors = () => {
  // NotificationMessage("error", msg || MESSAGES.somethingWentWrong);
};

export default function componentLoader(lazyComponent, attemptsLeft = 3) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

export const loader = (tip = "") => (
  <SpinFC tip={tip} style={{ height: "100%", width: "100%" }}>
    <div className="content" />
  </SpinFC>
);
