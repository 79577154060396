import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import { routes } from "./routes";
import ProtectedRoute from "./protectedRoute";
import ErrorBoundary from "./ErrorBoundary";
import { loader } from "../helpers/utils";
import PageNotFound from "../pages/PageNotFound";
import TopNavbar from "../components/navbar/TopNavbar";
import BottomNavbar from "../components/navbar/BottomNavbar";
import Footer from "../components/footer";
import { useContext } from "react";
import WebContext from "../context/web-context";
// import ManageCookies from "../components/Cookies";

function RouteConfig() {
  const { isLoggedin } = useContext(WebContext);
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "grid",
              placeItems: "center"
            }}>
            {loader()}
          </div>
        }>
        <TopNavbar isLoggedin={isLoggedin} />
        <BottomNavbar />
        <Routes>
          {routes.map((route, ind) => {
            return route.isProtected ? (
              <Route
                key={ind}
                path={route.path}
                element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              />
            ) : (
              <Route key={ind} path={route.path} element={route.element} />
            );
          })}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer isLoggedin={isLoggedin} />
        {/* <ManageCookies /> */}
      </Suspense>
    </ErrorBoundary>
  );
}

export default RouteConfig;
