import { Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../components/Button";
import WebLayout from "../../components/WebLayout";

const PageNotFoundUI = ({ title }) => {
  return <WebLayout>
    <Result
      status="500"
      title={title}
      // subTitle="Sorry, something went wrong."
      extra={
        <Link to={"/"}>
          <CustomButton type="primary">Back to Home</CustomButton>
        </Link>

      }
    /></WebLayout>;
};

export default React.memo(PageNotFoundUI);
