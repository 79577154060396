import { Button } from "antd";
import styled from "styled-components";
import { customTheme } from "../../helpers/theme";

const CustomButton = ({
  prefix,
  border,
  icon,
  block,
  background,
  width,
  type,
  onClick,
  color = customTheme.colours.white,
  fontFamily,
  children,
  size,
  href,
  ...rest
}) => {
  return (
    <Container
      href={href}
      block={block}
      prefix={prefix}
      size={size}
      icon={icon}
      fontFamily={fontFamily}
      border={border}
      width={width}
      background={background}
      type={type}
      onClick={onClick}
      color={color}
      loading={false}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default CustomButton;

const Container = styled(Button)`
  border-radius: 8px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  font-family: ${customTheme.fonts.fontFamily.medium};
`;
