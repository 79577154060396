const storage = window.localStorage;

const Storage = {
  clear() {
    storage.clear();
  },
  saveToken(token) {
    try {
      storage.setItem("universeToken", token);
    } catch (error) {
      return null;
    }
  },
  loadToken() {
    try {
      return storage.getItem("universeToken");
    } catch (error) {
      return null;
    }
  },
  deleteToken() {
    try {
      storage.removeItem("universeToken");
    } catch (error) {
      return null;
    }
  }
};

export default Storage;
