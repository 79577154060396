import { Col, Row } from "antd";

const WebLayout = ({ children }) => {
  return (
    <Row justify="center" style={{ width: "100%" }}>
      <Col span={18}>{children}</Col>
    </Row>
  );
};

export default WebLayout;
