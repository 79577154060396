import React, { useEffect, useState } from "react";
import NotificationMessage from "../components/NotificationMessage";
import { getAllPlans } from "../helpers/Api";
import { User } from "../helpers/Apiendpoint";
import { postRequest } from "../helpers/apiCalls";
import Storage from "../helpers/storage";

const WebContext = React.createContext();

export default WebContext;

export const WebProvider = (props) => {
  const [plans, setPlans] = useState([]);
  const [user, setUser] = useState("");
  const [isLoggedin, setIsloggedin] = useState(false);
  const [meeting, setMeeting] = useState(false);

  const userSignup = async (data) => {
    try {
      const res = await postRequest(User.createUser, data);
      if (res.status === 200) {
        NotificationMessage("success", res?.data?.message);
        return res?.status;
      } else {
        NotificationMessage("error", res?.response?.data?.message);
      }
    } catch (e) {
      NotificationMessage("error", e?.response?.data?.message);
    }
  };

  const userLogin = async (data) => {
    try {
      const res = await postRequest(User.loginUser, data);
      if (res.status === 200) {
        setUser(res?.data?.data);
        setIsloggedin(true);
        Storage.saveToken(res?.data?.data?.token);
        localStorage.setItem("uid", res?.data?.data?._id);
        localStorage.setItem("isloggedIn", "true");
        NotificationMessage("success", res?.data?.message);
        hanldlePlan();
      } else {
        NotificationMessage("error", res?.response?.data?.message);
        Storage.clear();
      }
    } catch (e) {
      NotificationMessage("error", e?.response?.data?.message);
    }
  };

  const hanldlePlan = async () => {
    const res = await getAllPlans();
    setPlans(res);
  };

  useEffect(() => {
    hanldlePlan();
  }, []);

  return (
    <WebContext.Provider
      value={{
        plans,
        userLogin,
        isLoggedin,
        setIsloggedin,
        user,
        setUser,
        userSignup,
        hanldlePlan,
        setMeeting,
        meeting
      }}>
      {props.children}
    </WebContext.Provider>
  );
};
