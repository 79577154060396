import axios from "axios";
// import NotificationMessage from "../components/NotificationMessage";
import Storage from "./storage";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
  baseURL: BASE_URL,
});

const token = Storage.loadToken();

if (token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

instance.interceptors.response.use(
  (response) => {
    const newToken = response?.data?.token;
    if (newToken) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    }
    const status = response?.status;
    const message = response?.message;
    if (!status) {
      // NotificationMessage("error", "Unknown error occurred.");
      return Promise.reject(new Error("Unknown error occurred."));
    }
    if (status === 200) {
      return response;
    } else if (status === 201) {
      return response;
    } else if (status === 401) {
      // NotificationMessage("error", "Your are not authiorized");

    } else {
      // NotificationMessage("error", message);
      return Promise.reject(new Error(message));
    }
  },
  (error) => {
    // if (error?.response?.status === 401) {
    //   NotificationMessage("error", error.response?.data.message);
    //   console.log(error.message);
    // } else {
    // NotificationMessage("error","UnAuthorized");
    // }
    // NotificationMessage("error", "API call failed.");
    return Promise.reject(error);
  }
);

//All request will wait 2 seconds before timeout
instance.defaults.timeout = 2000;

instance.defaults.withCredentials = false;

export default instance;
